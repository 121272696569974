import React, { Component } from "react";

export default class ShowParcels extends Component {
  handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    this.props.showParcels(isChecked);
  };

  render() {
    return (
      <div className="showParcelsContainer">
        <label>
          <input
            type="checkbox"
            defaultChecked={false}
            onChange={this.handleCheckboxChange}
          />
          Show Parcels
        </label>
      </div>
    );
  }
}
