import React, { Component } from "react";

import AdditionalFilters from "./AdditionalFilters";
import Initializing from "./Initializing";
import Map from "./Map";
import PasswordPopup from "./Password";
import ShowParcels from "./ShowParcels";
import Sidebar from "./Sidebar";

import logo from "./assets/images/bioneers-logo.png";
import logo2 from "./assets/images/cder-logo.png";

let DEFAULT_ECOSYSTEM_PLACEHOLDER = "Select ecosystem";
let DEFAULT_SUBECOSYSTEM_PLACEHOLDER = "Search by contributing ecosystem";
let DEFAULT_MUNICIPALITY_PLACEHOLDER = "Search by municipality";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: [],
      allStoriesEcosystem: [],
      searchedStoriesEcosystem: [],
      filteredStoriesEcosystem: [],
      ecosystemIndex: null,
      activeEcosystem: null,
      activeSubEcosystem: null,
      activeMunicipality: null,
      activeStory: null,
      activeStoryClicked: null,
      searchEcosystem: DEFAULT_ECOSYSTEM_PLACEHOLDER,
      searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
      searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
      filterDemocraticOutnumberRepublican: false,
      geoJSONData: null,
      initializing: true,
      isPasswordProtected: process.env.REACT_APP_PASSWORD_PROTECTED === "true",
      isPasswordCorrect: false,
      logoPath: logo,
      logoPath2: logo2,
      ecosystems: [],
      ecosystemsWithParcels: [],
      showParcels: false,
      subEcosystemOptions: [],
      showStories: false,
      showAdditionalFilters: false,
      itemsDisabled: true,
      tributaryKey: "Tributaries that Border or Flow through the Municipality",
      waterSourceKey: "Major Bay Water Sources",
    };
  }

  async componentDidMount() {
    await this.fetchData();
    this.fetchGeoJSON();
  }

  // Fetch data from our Express.js server
  async fetchData() {
    try {
      const response = await fetch("/fetchData");
      const { ecosystems, ecosystemsWithParcels, stories } =
        await response.json();

      this.setState({
        ecosystems,
        ecosystemsWithParcels,
        stories,
      });
    } catch (error) {
      console.log(error);
    }
  }

  // Fetch GeoJSON bounding boxes from our Express.js server
  // These are used for map flyTo events
  async fetchGeoJSON() {
    try {
      const ecosystems = this.reformatEcosystemForMap(
        this.state.ecosystems,
      ).join(",");
      const response = await fetch(`/fetchGeoJSON?layers=${ecosystems}`);
      const geoJSONData = await response.json();

      this.setState({
        geoJSONData,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handlePasswordCorrect = () => {
    this.setState({ isPasswordCorrect: true });
  };

  // build filter map for secondary dropdown
  searchMap = () => {
    let searchMap = {};
    this.state.ecosystems.map((ecosystem, index) => {
      const ecosystemSet = new Set(
        this.state.stories[index]
          .map((story) => {
            return [
              ...(story[this.state.tributaryKey] || "").split(","),
              ...(story[this.state.waterSourceKey] || "").split(","),
            ];
          })
          .flat()
          .map((item) => item.trim()),
      );
      searchMap[ecosystem] = Array.from(ecosystemSet)
        .filter((item) => item)
        .sort();
      return searchMap;
    });
    return searchMap;
  };

  sortStories = (data) => {
    let sortedStories;
    sortedStories = data.sort(function (a, b) {
      let x = a["Name of Municipality"].toLowerCase();
      let y = b["Name of Municipality"].toLowerCase();
      if (x > y) {
        return 1;
      }
      if (x < y) {
        return -1;
      }
      return 0;
    });
    return sortedStories;
  };

  // function triggered when a primary dropdown option is selected
  handleEcosystemChange = (option) => {
    if (option === null) {
      this.resetStoriesAndMap();
    } else {
      const ecosystem = option.value;
      this.handleEcosystemSearch(ecosystem);
      this.setState({
        searchEcosystem: ecosystem,
        subEcosystemOptions: this.searchMap()[ecosystem],
        activeEcosystem: ecosystem,
        searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
        searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
        activeSubEcosystem: null,
        activeStory: null,
        activeMunicipality: null,
        showParcels: false,
      });
    }
  };

  // function triggered when a secondary dropdown option is selected
  handleSubecosystemChange = (option) => {
    if (option === null) {
      this.resetStoriesAndMapToEcosystem();
    } else if (this.state.searchEcosystem !== null) {
      const item = option.value;
      this.handleSubecosystemSearch(this.state.searchEcosystem, item);
      this.setState({
        searchSubEcosystem: item,
        activeSubEcosystem: item,
        activeStory: null,
      });
    }
  };

  // function triggered when a municipality is selected
  handleMunicipalityChange = (option) => {
    if (option === null) {
      this.resetStoriesAndMapToEcosystem();
    } else if (this.state.searchEcosystem !== null) {
      const item = option.value;
      this.handleMunicipalitySearch(this.state.searchEcosystem, item);
      this.setState({
        searchMunicipality: item,
        activeMunicipality: item,
      });
    }
  };

  // filter based on primary dropdown
  handleEcosystemSearch = (ecosystem) => {
    let searchedStories = this.state.stories[
      this.state.ecosystems.indexOf(ecosystem)
    ].filter((story) => {
      return story["Name of Municipality"];
    });
    if (searchedStories) {
      searchedStories = this.sortStories(searchedStories);
      this.setState({
        allStoriesEcosystem: searchedStories,
        searchedStoriesEcosystem: searchedStories,
        filteredStoriesEcosystem: searchedStories,
        showStories: true,
        showAdditionalFilters: true,
      });
    }
  };

  // search for subEcosystems
  handleSubecosystemSearch = (ecosystem, item) => {
    let searchedStories = [];
    searchedStories = this.state.allStoriesEcosystem.filter((story) => {
      const tributaries = new Set(
        (story[this.state.tributaryKey] || "")
          .split(",")
          .map((t) => t.trim().toLowerCase()),
      );
      const waterSources = new Set(
        (story[this.state.waterSourceKey] || "")
          .split(",")
          .map((w) => w.trim().toLowerCase()),
      );
      return (
        tributaries.has(item.toLowerCase()) ||
        waterSources.has(item.toLowerCase())
      );
    });
    if (searchedStories) {
      searchedStories = this.sortStories(searchedStories);
      this.setState({
        searchedStoriesEcosystem: searchedStories,
        filteredStoriesEcosystem: searchedStories,
        searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
        showStories: true,
        showAdditionalFilters: true,
      });
    }
  };

  // filter based on secondary dropdown
  handleMunicipalitySearch = (ecosystem, item) => {
    let searchedStories = this.state.allStoriesEcosystem.filter((story) => {
      return story["Name of Municipality"].toLowerCase() === item.toLowerCase();
    });
    if (searchedStories) {
      this.setState({
        searchedStoriesEcosystem: searchedStories,
        filteredStoriesEcosystem: searchedStories,
        activeSubEcosystem: null,
        searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
        showStories: true,
        showAdditionalFilters: false,
      });
    }
  };

  // handle additional filter checkboxes
  handleAdditionalFilters = (checkedFilters) => {
    let filteredStories = this.state.searchedStoriesEcosystem;

    if (
      checkedFilters.filter((x) => x.match("initiative-lawmaking-by-people"))
        .length !== 0
    ) {
      filteredStories = filteredStories.filter((story) => {
        if (
          checkedFilters.includes("initiative-lawmaking-by-people-yes") &&
          story["Initiative Lawmaking by People"] === "Yes"
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else if (
          checkedFilters.includes(
            "initiative-lawmaking-by-people-yes-conditionally",
          ) &&
          story["Initiative Lawmaking by People"] === "Yes, conditionally"
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else {
          return "";
        }
      });
    }

    if (
      checkedFilters.includes("municipality-population-5000") ||
      checkedFilters.includes("municipality-population-10000") ||
      checkedFilters.includes("municipality-population-over")
    ) {
      filteredStories = filteredStories.filter((story) => {
        if (
          checkedFilters.includes("municipality-population-5000") &&
          Number(story["Population of Municipality"]) < 5000
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else if (
          checkedFilters.includes("municipality-population-10000") &&
          Number(story["Population of Municipality"]) > 5000 &&
          Number(story["Population of Municipality"]) < 10000
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else if (
          checkedFilters.includes("municipality-population-over") &&
          Number(story["Population of Municipality"]) > 10000
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else {
          return "";
        }
      });
    }

    if (checkedFilters.includes("outnumber")) {
      filteredStories = filteredStories.filter((story) => {
        if (
          Number(story["Democratic Registered Voters"]) >
          Number(story["Republican Registered Voters"])
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else {
          return "";
        }
      });
    }

    if (checkedFilters.includes("ontheriver")) {
      filteredStories = filteredStories.filter((story) => {
        if (
          story["Major Bay Water Source Flows through Municipality"] ===
            "Yes" ||
          story["River Borders or Flows Through Municipality"] === "Yes"
        ) {
          return story["Name of Municipality"].toLowerCase();
        } else {
          return "";
        }
      });
    }

    if (checkedFilters.filter((x) => x.match("major-waterways")).length !== 0) {
      let majorWaterways = checkedFilters.filter((x) =>
        x.match("major-waterways"),
      );
      for (let i in majorWaterways) {
        majorWaterways[i] = majorWaterways[i].replace("major-waterways-", "");
      }
      filteredStories = filteredStories.filter((story) => {
        const tributaries = new Set(
          (story[this.state.tributaryKey] || "")
            .split(",")
            .map((t) => t.trim().toLowerCase()),
        );
        const waterSources = new Set(
          (story[this.state.waterSourceKey] || "")
            .split(",")
            .map((w) => w.trim().toLowerCase()),
        );
        const matchingTributaries = Array.from(tributaries).filter(
          (tributary) =>
            majorWaterways.includes(
              tributary.replace(/[.]/g, "").replace(/[ ]/g, "-").toLowerCase(),
            ),
        );
        const matchingWaterSources = Array.from(waterSources).filter(
          (waterSource) =>
            majorWaterways.includes(
              waterSource
                .replace(/[.]/g, "")
                .replace(/[ ]/g, "-")
                .toLowerCase(),
            ),
        );

        if (majorWaterways.includes("any")) {
          if (tributaries.size > 0 || waterSources.size > 0) {
            return story["Name of Municipality"].toLowerCase();
          }
        } else if (
          matchingTributaries.length > 0 ||
          matchingWaterSources.length > 0
        ) {
          return story["Name of Municipality"].toLowerCase();
        }
        return "";
      });
    }

    if (checkedFilters.includes("environmental-active")) {
      filteredStories = filteredStories.filter((story) => {
        if (story["Environmental Organizations"]) {
          return story["Name of Municipality"].toLowerCase();
        } else {
          return "";
        }
      });
    }

    filteredStories = this.sortStories(filteredStories);
    this.setState({
      filteredStoriesEcosystem: filteredStories,
      showStories: true,
      showAdditionalFilters: true,
    });
  };

  showMapPointStories = (stories) => {
    let storyTitles = stories.map((story) => story.title);
    let filteredStories = [];
    filteredStories = this.props.stories.filter((story) =>
      storyTitles.includes(story.title),
    );
    if (filteredStories) {
      this.setState({
        stories: filteredStories,
        activeStory: filteredStories[0],
      });
    }
  };

  handleStoryClick = (story) => {
    this.setState({ activeStory: story });
  };

  // function to handle a reset triggered by e.g. clicking the X in the dropdown
  resetStoriesAndMap = () => {
    this.setState({
      allStoriesEcosystem: [],
      searchedStoriesEcosystem: [],
      activeStory: null,
      activeEcosystem: null,
      activeMunicipality: null,
      activeSubEcosystem: null,
      searchEcosystem: DEFAULT_ECOSYSTEM_PLACEHOLDER,
      searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
      searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
      showParcels: false,
      subEcosystemOptions: [],
      showStories: false,
      showAdditionalFilters: false,
      itemsDisabled: true,
    });
  };

  // function to reset active story
  resetActiveStory = () => {
    this.setState({
      activeStory: null,
    });
  };

  // function to handle a reset triggered by e.g. clicking the X in the dropdown
  resetStoriesAndMapToEcosystem = () => {
    this.setState({
      activeStory: null,
      activeSubEcosystem: null,
      activeMunicipality: null,
      searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
      searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
    });
    this.handleEcosystemSearch(this.state.searchEcosystem);
  };

  // filter ecosystems based on map feature click event
  handleMapFeatureClick = (feature) => {
    this.setState({
      searchEcosystem: feature,
      subEcosystemOptions: this.searchMap()[feature],
      activeEcosystem: feature,
      activeSubEcosystem: null,
      searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
      searchMunicipality: DEFAULT_MUNICIPALITY_PLACEHOLDER,
    });
    this.handleEcosystemSearch(feature);
  };

  // filter ecosystems based on map feature click event
  handleDetailFeatureClick = (detail) => {
    const { ecosystems, activeEcosystem, stories } = this.state;
    const e = ecosystems.indexOf(activeEcosystem);

    for (let i in stories[e]) {
      const story = stories[e][i];
      const { NAME } = detail[0].properties;
      const nameOfMunicipality = story["Name of Municipality"];
      const typeOfMunicipality = story["Type of Municipality"];

      if (
        (NAME.includes(nameOfMunicipality) ||
          nameOfMunicipality.includes(NAME)) &&
        ["tribe", "village", "city"].includes(typeOfMunicipality)
      ) {
        this.handleStoryClick(story);
        this.setState({
          activeStoryClicked: story,
          activeSubEcosystem: null,
          searchSubEcosystem: DEFAULT_SUBECOSYSTEM_PLACEHOLDER,
          searchMunicipality: nameOfMunicipality,
          activeMunicipality: nameOfMunicipality,
        });
        this.handleMunicipalitySearch(
          this.state.searchEcosystem,
          nameOfMunicipality,
        );
        return;
      }
    }
  };

  // populating the ecosystems in the dropdown
  buildSearchEcosystems = () => {
    let ecosystems = this.searchMap();
    return Object.keys(ecosystems);
  };

  reformatEcosystemForMap = (ecosystems) => {
    const formattedEcosystems = ecosystems.map((str) => {
      let formattedStr = str;
      formattedStr = formattedStr
        .replace(/( River| Bay| Valley) Ecosystem/gi, "")
        .replace(/[ .]/g, "")
        .toLowerCase();
      return formattedStr;
    });

    return formattedEcosystems;
  };

  reformatSubEcosystemForMap = (obj) => {
    const sanitizeString = (str) => {
      return str
        .replace(/( River| Bay| Valley) Ecosystem/gi, "")
        .replace(/[ .]/g, "")
        .toLowerCase();
    };

    const sanitizeArray = (arr) => {
      return arr.map((item) => {
        if (typeof item === "string") {
          return sanitizeString(item);
        } else if (Array.isArray(item)) {
          return sanitizeArray(item);
        } else if (typeof item === "object") {
          return this.reformatSubEcosystemForMap(item);
        } else {
          return item;
        }
      });
    };

    const sanitizedObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const sanitizedKey = sanitizeString(key);

        if (Array.isArray(value)) {
          sanitizedObj[sanitizedKey] = sanitizeArray(value);
        } else if (typeof value === "object") {
          sanitizedObj[sanitizedKey] = this.reformatSubEcosystemForMap(value);
        } else {
          sanitizedObj[sanitizedKey] = sanitizeString(value);
        }
      }
    }

    return sanitizedObj;
  };

  reformatEcosystem = (ecosystem) => {
    // regex to format ecosystem title case to slug to match in Mapbox
    if (ecosystem !== null) {
      ecosystem = ecosystem
        .replace(/( River| Bay| Valley) Ecosystem/gi, "")
        .replace(/[ ,.]/g, "")
        .toLowerCase();
    }
    return ecosystem;
  };

  reformatEcosystemForSearch = (ecosystem) => {
    if (ecosystem !== null) {
      return ecosystem.replace(" Ecosystem", "");
    }
  };

  handleShowParcelsChange = (isChecked) => {
    this.setState({
      showParcels: isChecked,
    });
  };

  disableItems = () => {
    this.setState({
      itemsDisabled: true,
    });
  };

  enableItems = () => {
    this.setState({
      itemsDisabled: false,
    });
  };

  disableInitializing = () => {
    this.setState({
      initializing: false,
    });
  };

  render() {
    let { isPasswordCorrect, isPasswordProtected } = this.state;

    if (!isPasswordProtected) {
      isPasswordCorrect = true;
    }

    return (
      <div
        className={`app-container ${
          isPasswordCorrect ? "password-correct" : ""
        }`}
      >
        {isPasswordCorrect ? (
          <>
            <Initializing initializing={this.state.initializing} />
            {this.state.ecosystems.length > 0 &&
              this.state.geoJSONData && ( // Check if this.state.ecosystems is not null
                <Map
                  activeEcosystem={this.reformatEcosystem(
                    this.state.activeEcosystem,
                  )}
                  activeMunicipality={this.state.activeMunicipality}
                  activeStory={this.state.activeStory}
                  activeSubEcosystem={this.reformatEcosystem(
                    this.state.activeSubEcosystem,
                  )}
                  disableInitializing={this.disableInitializing}
                  disableItems={this.disableItems}
                  ecosystems={this.buildSearchEcosystems()}
                  ecosystemsForMap={this.reformatEcosystemForMap(
                    this.state.ecosystems,
                  )}
                  ecosystemsWithParcels={this.state.ecosystemsWithParcels.map(
                    this.reformatEcosystem,
                  )}
                  enableItems={this.enableItems}
                  geoJSONData={this.state.geoJSONData}
                  itemsDisabled={this.state.itemsDisabled}
                  onDetailClick={this.handleDetailFeatureClick}
                  onMapFeatureClick={this.handleMapFeatureClick}
                  resetActiveStory={this.resetActiveStory}
                  resetSubEcosystem={this.resetSubEcosystem}
                  showParcels={this.state.showParcels}
                  subEcosystems={this.searchMap()}
                  subEcosystemsForMap={this.reformatSubEcosystemForMap(
                    this.searchMap(),
                  )}
                />
              )}
            <AdditionalFilters
              activeEcosystem={this.reformatEcosystemForSearch(
                this.state.activeEcosystem,
              )}
              activeMunicipality={this.state.activeMunicipality}
              activeSubEcosystem={this.reformatEcosystem(
                this.state.activeSubEcosystem,
              )}
              handleAdditionalFilters={this.handleAdditionalFilters}
              showAdditionalFilters={this.state.showAdditionalFilters}
              subEcosystemOptions={this.state.subEcosystemOptions}
            />
            {this.state.ecosystemsWithParcels.includes(
              this.state.activeEcosystem,
            ) && <ShowParcels showParcels={this.handleShowParcelsChange} />}
            <Sidebar
              activeStory={this.state.activeStory}
              activeStoryClicked={this.state.activeStoryClicked}
              allStories={this.state.allStoriesEcosystem}
              ecosystems={this.buildSearchEcosystems()}
              filterDemocraticOutnumberRepublican={
                this.state.filterDemocraticOutnumberRepublican
              }
              handleEcosystemChange={this.handleEcosystemChange}
              handleMunicipalityChange={this.handleMunicipalityChange}
              handleSearch={this.handleSearch}
              handleSubecosystemChange={this.handleSubecosystemChange}
              itemsDisabled={this.state.itemsDisabled}
              logoPath={this.state.logoPath}
              logoPath2={this.state.logoPath2}
              onStoryClick={this.handleStoryClick}
              searchEcosystem={this.state.searchEcosystem}
              searchMap={this.searchMap()}
              searchMunicipality={this.state.searchMunicipality}
              searchSubEcosystem={this.state.searchSubEcosystem}
              showStories={this.state.showStories}
              stories={this.state.filteredStoriesEcosystem}
              subEcosystemOptions={this.state.subEcosystemOptions}
              tributaryKey={this.state.tributaryKey}
              waterSourceKey={this.state.waterSourceKey}
            />
          </>
        ) : (
          <PasswordPopup
            logoPath={this.state.logoPath}
            logoPath2={this.state.logoPath2}
            onPasswordCorrect={this.handlePasswordCorrect}
          />
        )}
      </div>
    );
  }
}

export default App;
